<template>
  <div>
    <Pane />
    <div class="container">
      <a-form
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
        :colon="false"
        @submit="handleSubmit"
        :form="form"
      >
        <a-row>

          <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="接收人">
                {{ detail.receiveName }}
              </a-form-item>
            </a-col>
          <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="接收部门">
                {{ detail.deptName }}
              </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="发放时间">
              {{ detail.grantTime }}
            </a-form-item>
          </a-col>
        </a-row>
        <div class="between" style="margin-bottom: 5px;">
          <div class="title">发放资料情况</div>
        </div>
        <a-table bordered :dataSource="detail.dataList" :pagination="false">
          <a-table-column title="资料名称" data-index="name"> </a-table-column>
          <a-table-column title="资料数量" align="center">
            <template slot-scope="text">
              <span style="color: #1890ff">{{ text.count }}</span>
            </template>
          </a-table-column>
        </a-table>
        <div class="center">
          <a-space>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import organization from "@/mixins/organization";
import EmployeeSelector from "@/components/employee-selector";

import request from "@/api/request";

function fetchDetail(id) {
  return request({
    url: "/office-service/technicalInformationGrant/detail/" + id,
  });
}

export default {
  name: "courseAdd",

  mixins: [organization],
  components: {
    EmployeeSelector,
  },
  data() {
    return {
      detail: {},
      visible: false,
      master: {},
      form: this.$form.createForm(this),
      formModel: this.$form.createForm(this),
      cover: {},
      chapters: [],
      list: [],
      loading: false
    };
  },
  mounted(){
    fetchDetail(this.$route.query.id).then(res=>{
      this.detail = res
    })
  },
  methods: {
  }
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px;
}

.center {
  padding: 40px;
}
</style>
